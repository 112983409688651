import { useState, createContext } from 'react';
import {
  getUserQuery,
  updateUserMutation,
  calendarConnectedQuery,
  getCalendarAuthLinkQuery,
  disconnectCalendarQuery,
  getAccountQuery,
  getTrackQuery,
  getTracksQuery,
  playTrackMutation
} from './queries';
import { queryWithError } from 'utils';
import { API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';

export const UserContext = createContext();

export const UserContextProvider = (props) => {
  const [account, setAccount] = useState(null);
  const [track, setTrack] = useState(null);
  const [tracks, setTracks] = useState([]);

  const logoutUser = () => {
    setAccount(null);
    setTrack(null);
    setTracks([]);
    localStorage.clear(); // Or target specific keys if you have other data you want to preserve
  };

  async function getAccount(inputCode) {
    try {

      let queryData = graphqlOperation(getAccountQuery, { accessCode: inputCode })

      const result = await API.graphql(queryData);
      if (result.data.getAccount) {
        setAccount(result.data.getAccount);
        return true; // Access code is valid
      } else {
        return false; // Access code is invalid
      }
    } catch (error) {
      console.error('Error fetching account data:', error);
      return false; // In case of error, consider the access code as invalid
    }
  }

  async function getTrack(id) {
    try {
      const result = await API.graphql(graphqlOperation(getTrackQuery, { id }));
      setTrack(result.data.getTrack);
    } catch (error) {
      console.error('Error fetching track data:', error);
    }
  }
  async function getTracks() {
    try {
      let accessCode = localStorage.getItem('accessCode');
      getAccount(accessCode)
      const result = await API.graphql(graphqlOperation(getTracksQuery));
      setTracks(result.data.getTracks);
    } catch (error) {
      console.error('Error fetching tracks:', error);
    }
  }
  
  async function playTrack(accountId, trackId) {
    try {
      const result = await API.graphql(graphqlOperation(playTrackMutation, { accountId, trackId }));
      // Process result if needed
      if (result.data.playTrack) {
        return result.data.playTrack; // Access code is valid
      } else {
        return false; // Access code is invalid
      }
      
    } catch (error) {
      console.error('Error playing track:', error);
    }
  }
  
  

  const ctx = {
    account,
    track,
    tracks,
    getAccount,
    getTrack,
    getTracks,
    playTrack,
    logoutUser,
  }

  return (
    <UserContext.Provider value={ctx}>{props.children}</UserContext.Provider>
  )
}
