import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { useState } from 'react';

import 'bootstrap/dist/css/bootstrap.css';

import AuthWrapper from 'components/authWrapper'
import Header from 'components/header'
import Footer from 'components/footer'
import Home from 'pages/Home'
import AgreePage from 'pages/AgreePage'
import Profile from 'pages/Profile';
import Tracks from 'pages/Tracks';
import AudioPlayer from 'pages/AudioPlayer';

// Context 
import { UserContextProvider } from 'context/user-context'

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon' // for Luxon
import { LocalizationProvider } from '@mui/x-date-pickers'// for Luxon


function App() {
    const [accessCode, setAccessCode] = useState('');
    const verifyAccessCode = (code) => {
      return code === "correctAccessCode";
    };    
  return (
    <BrowserRouter className='App'>
      <UserContextProvider>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Routes>
            <Route path='/' element={<Home setAccessCode={setAccessCode} />} />
            <Route path='/AgreePage' element={<AgreePage accessCode={accessCode} verifyAccessCode={verifyAccessCode} />} />
            <Route
              path='/profile'
              element={<AuthWrapper component={<Profile />} />}
            />
            <Route
              path='/login'
              element={
                <AuthWrapper component={<p>Your are Logged in now!</p>} />
              }
            />
            <Route
              path='/tracks'
              element={
                <AuthWrapper component={<Tracks />} />
              }
            />
            <Route
              path='/AudioPlayer'
              element={
                <AuthWrapper component={<AudioPlayer />} />
              }
            />
          </Routes>
        </LocalizationProvider>
      </UserContextProvider>
    </BrowserRouter>
  );
}

export default App;
