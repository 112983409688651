import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { UserContext } from "../context/user-context/index";
import { useTheme } from '@mui/material/styles';

const AgreePage = () => {
  const { account } = useContext(UserContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleAccessCodeSubmit = () => {
    if (account.accessCode && account.id) {
      localStorage.setItem("accessCode", account.accessCode);
      localStorage.setItem("accountId", account.id);
      navigate("/Tracks"); // Navigate to the next page
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        color: "white",
        p: 2,
      }}
    >
      <video
        autoPlay
        muted
        loop
        playsInline
        disablePictureInPicture
        controlsList="nodownload nofullscreen noremoteplayback"
        style={{
          position: "absolute",
          width: "100%",
          left: 0,
          top: 0,
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      >
        <source src="/assets/qnh.mp4" type="video/mp4" />
      </video>

      <Typography 
      variant="h5" 
      sx={{ 
        position: "absolute", 
        top: 30, 
        left: 20, 
        display: {xs:"none", lg:"block"},
        }}>
        A.L.I.C.E  AI System
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Box
          sx={{
            position: "absolute",
            width: { xs: "100%", sm: "100%", md: "90%", lg: "80%" }, // Responsive width
            height: { xs: "100%", sm: "100%", md: "90%", lg: "80%" }, // Responsive height
            
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "rgba(0, 0, 0, 0.7)", // Dark overlay with opacity
            borderRadius: "16px", // Optional: if you want rounded corners
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 2, // Make sure it's above the video
            pt: 5,
            p: { xs: 2, sm: 3, md: 4 }, // Responsive padding
            overflowY: "auto", // Add a scrollbar if the content overflows vertically
            [theme.breakpoints.down('sm')]: {
              width: '95%', // Covers more page as the size decreases
              height: '95%', // Covers more page as the size decreases
              fontSize: '0.8rem',
            },
          }}
        >
          <Typography 
          variant="h4" 
          gutterBottom
          sx={{
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem' }, // Responsive font size
          }}
          >
            A.L.I.C.E  AI System
          </Typography>
          <Typography 
          varient="h5"
          sx={{
            fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem', lg: '2rem' }, // Responsive font size
          }}
          >
            Disclaimer
            <br />
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ 
              mb: 4, 
              maxWidth: "md", 
              textAlign: "center",
              fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem', lg: '1.1rem' }, // Responsive font size
            }}
          >
            <i>
              Please read the following disclaimer carefully before
              participating in any energy therapy sessions.
            </i>
            <br />
            <br />
            Results from Advance Energy Therapy sessions may vary from person to
            person. While many individuals experience positive outcomes, Advance
            Energy Therapy is not a substitute for professional medical advice,
            diagnosis, or treatment. Advance Energy Therapy is not intended to
            diagnose, treat, cure, or prevent any disease or medical condition.
            It is a tool for promoting relaxation, stress reduction, and overall
            well-being. If you have a specific health concern, consult with a
            healthcare professional.
            <br />
            <br />
            By participating in Advance Energy Therapy sessions, you acknowledge
            that you have read, understood, and agree to this disclaimer. If you
            do not agree with any part of this disclaimer, it is advised not to
            proceed with Advance Energy Therapy sessions.
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={handleAccessCodeSubmit}
            sx={{
              backgroundImage: "linear-gradient(to right, #F9FF53, #2DDE91)",
              color: "black",
              border: "none",
              borderRadius: "20px",
              px: 5,
              "&:hover": {
                backgroundImage: "linear-gradient(to right, #F9FF53, #2DDE91)",
                opacity: 0.9,
              },
            }}
          >
            I Understand
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AgreePage;
