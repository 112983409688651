import { useEffect, useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
    Container,
    Typography
  } from '@mui/material'

import { UserContext } from 'context/user-context'

export const Profile = () => {

    const {
        user,
        getUser,
    } = useContext(UserContext)

  useEffect(() => {
    getUser() // From UserContext
  }, [])

  

  return (
    <Container maxWidth='sm' sx={{ my: 5 }}>
        <Typography variant='h1'>Your Name</Typography>
    </Container>
  )
}

export default Profile