import { useContext, useEffect, useState } from 'react'
import { Box, Stack, Typography, Link } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Instagram, LinkedIn, Twitter, Facebook } from '@mui/icons-material'

const ICON_SIZE = '45px'

const FooterLink = ({ children, ...props }) => (
  <Link color='inherit' {...props}>
    {children}
  </Link>
)

export default function Footer() {

  useEffect(() => {
   
  }, [])

  return (
    <FooterLayout
      p={4}
      pt={6}
      backgroundColor='primary.main'
      color='text.light'
      component='footer'
    >
      <div>
        <Typography variant='subtitle2' mb={2}>
          @2024 Productive Cloud Solutions. All rights reserved.
        </Typography>
        <Typography variant='subtitle2'>
          <FooterLink href='https://productivecloudsolutions.com/'>
            Built by Productive Cloud Solutions.
          </FooterLink>
        </Typography>
      </div>

      {/* Only show the social media links if values are present in Airtable */}
      {/* <Stack direction='row' spacing={4}>
        {socials['instagram'] && (
          <FooterLink href={socials['instagram']}>
            <Instagram sx={{ fontSize: ICON_SIZE }} />
          </FooterLink>
        )}
        {socials['linkedin'] && (
          <FooterLink href={socials['linkedin']}>
            <LinkedIn sx={{ fontSize: ICON_SIZE }} />
          </FooterLink>
        )}
        {socials['twitter'] && (
          <FooterLink href={socials['twitter']}>
            <Twitter sx={{ fontSize: ICON_SIZE }} />
          </FooterLink>
        )}
        {socials['facebook'] && (
          <FooterLink href={socials['facebook']}>
            <Facebook sx={{ fontSize: ICON_SIZE }} />
          </FooterLink>
        )}
      </Stack> */}
    </FooterLayout>
  )

}

const FooterLayout = styled(Box)(`
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
    gap: ${ICON_SIZE};
    & div:first-of-type {
        flex: 1 1 600px;
        @media (max-width: 975px) {
            text-align: center;
        }
    }
`)
