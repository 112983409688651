import React, { useState, useContext, useEffect, useRef } from 'react';
import { UserContext } from '../context/user-context/index';
import { Box, Typography, Stack, Button, Slider } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@aws-amplify/ui-react';

const AudioPlayer = () => {
  const audioRef = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { account, playTrack } = useContext(UserContext);
  const [currentTime, setCurrentTime] = useState('0:00');
  const [track, setTrack] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(false);

  useEffect(() => {
    handlePlayAudio(state.trackId);

    return () => {
      alert("Exiting will forfeit your current session.");
    }
  }, []);

  useEffect(() => {
    if (!track?.url) return;

    const audio = new Audio(track.url);
    audioRef.current = audio;

    const handleTimeUpdate = () => {
      const currentMinutes = Math.floor(audio.currentTime / 60);
      const currentSeconds = Math.floor(audio.currentTime % 60);
      setCurrentTime(`${currentMinutes}:${currentSeconds < 10 ? '0' : ''}${currentSeconds}`);
    };
    
    const handleAudioEnd = () => {
      navigate('/Tracks');
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleAudioEnd);

    // Check if autoplay is supported
    audio.play().then(() => {
      setIsPlaying(true);
    }).catch((error) => {
      console.log('Autoplay not allowed:', error);
      setShowPlayButton(true);
    });

    return () => {
      audio.pause();
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleAudioEnd);
    }
  }, [track]);

  const handlePlayAudio = async (selectedTrackId) => {
    if (selectedTrackId && account) {
      const response = await playTrack(account.id, selectedTrackId);

      if (!response) {
        alert('An error has occurred.');
        return;
      }

      setTrack(response);
    }
  };

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Box sx={{padding: '20px', width: '99vw', height: '90vh', display: 'flex', flexDirection: "column", textAlign: 'center'}}>
      <video 
        autoPlay
        muted
        loop
        playsInline
        disablePictureInPicture
        controlsList="nodownload nofullscreen noremoteplayback"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          objectFit: 'cover',
          zIndex: -1,
        }}>
        <source src="/assets/audio.mp4" type="video/mp4" />
      </video>
      <Typography variant="h4" color="#2DDE91">
        {track ? track.name : "Session"}
      </Typography>
      <Box sx={{ flexGrow: 1 }}/>
      <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "5px"}}>
        <Typography variant="h2" sx={{ color: "#BC4520", textShadow: "1px 1px 2px black"}}>{currentTime}</Typography>
        {showPlayButton && (
          <Button onClick={togglePlay} variant="contained" color="primary" style={{width: "300px"}}>
            {isPlaying ? 'Pause' : 'Play'}
          </Button>
        )}
      </Grid>
    </Box>
  );
};

export default AudioPlayer;