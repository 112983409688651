export const getUserQuery = `
query MyQuery {
  getUser {
    username
    registered
    id
    type
    lastName
    gender
    firstName
    email
    DOB
  }
}`

export const calendarConnectedQuery = `
query MyQuery {
  calendarConnected
}`
export const disconnectCalendarQuery = `
mutation MyMutation {
  disconnectCalendar
}`

export const getCalendarAuthLinkQuery = `
query MyQuery {
  getCalendarAuthLink
}`

export const updateUserMutation = `mutation MyMutation($user:UserInput!) {
  updateUser(user: $user) {
    DOB
    email
    firstName
    gender
    id
    lastName
    registered
    type
    username
  }
}`

export const getAccountQuery = `
  query GetAccount($accessCode: String!) {
    getAccount(accessCode: $accessCode) {
      accessCode
      firstName
      id
      lastName
      numPlays
    }
  }
`;

export const getTrackQuery = `
  query GetTrack($id: String!) {
    getTrack(id: $id) {
      description
      id
      instructions
      name
    }
  }
`;

export const getTracksQuery = `
  query GetTracks {
    getTracks {
      description
      id
      name
      instructions
    }
  }
`;

export const playTrackMutation = `
  mutation PlayTrack($accountId: ID!, $trackId: ID!) {
    playTrack(accountId: $accountId, trackId: $trackId) {
      description
      id
      instructions
      name
      url
    }
  }
`;
