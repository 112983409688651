import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../context/user-context/index";
import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Modal,
  Box,
  Hidden,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const Tracks = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { account, getTrack, tracks, getTracks, logoutUser } =
    useContext(UserContext);

  const [selectedTrack, setSelectedTrack] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  useEffect(() => {
    getTracks();
  }, []);

  const handleTrackClick = async (trackId) => {
    setSelectedTrack(trackId);
  };

  const handleStartToggle = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const handleConfirmStart = () => {
    navigate("/AudioPlayer", {
      state: { trackId: selectedTrack, accountId: account?.id },
    });
  };

  const handleLogout = () => {
    logoutUser(); // Call the logout method from your context
    navigate("/"); // Navigate to the home or login page
  };

  const renderConfirmationModal = () => (
    <Modal
      open={openConfirmationModal}
      onClose={handleStartToggle}
      sx={{
        backdropFilter: "blur(4px)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "white",
          backgroundColor: "rgb(7,33,22,0.7)",
          borderRadius: "20px",
          padding: 5,
          width: { xs: "90%", sm: "80%", md: "70%", lg: "60%" }, // Responsive width
          height: 'auto', // Adjust height as needed
          fontSize: '0.8rem',
          boxSizing: 'border-box', // Make sure padding doesn't affect the width
          overflowY: 'auto', // Add scroll for smaller devices
          [theme.breakpoints.down('md')]: {
            padding: 3, // Smaller padding for smaller screens
            fontSize: '0.7rem', // Smaller font size for smaller screens
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{ marginBottom: 2, color: "#2DDE91" }}
          align="center"
        >
          {account?.numPlays && account?.numPlays > 0
            ? "Are you sure you want to begin this session"
            : "You have no remaining sessions."}
        </Typography>
        {account?.numPlays && account?.numPlays > 0 ? (
          <Typography variant="h5" align="center" sx={{ color: "#A8411F" }}>
            {" "}
            Total remaining plays: {account?.numPlays}
          </Typography>
        ) : (
          ""
        )}
        <Typography variant="body2" sx={{ marginTop: 5 }}>
          {account?.numPlays && account?.numPlays > 0
            ? "Once initiated, this session remains active. Please allocate an appropriate amount of time for its completion. Exiting or refreshing this page will terminate the session."
            : ""}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent:
              account?.numPlays && account?.numPlays > 0
                ? "space-between"
                : "center",
            alignItems:
              account?.numPlays && account?.numPlays > 0 ? "normal" : "center",
            marginTop: 5,
          }}
        >
          {account?.numPlays && account?.numPlays > 0 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmStart}
              sx={{
                backgroundImage: "linear-gradient(to right, #F9FF53, #2DDE91)",
                color: "black",
                border: "none",
                borderRadius: "20px",
                mt: 2,
                "&:hover": {
                  backgroundImage:
                    "linear-gradient(to right, #F9FF53, #2DDE91)",
                  opacity: 0.9,
                },
                [theme.breakpoints.down('md')]: {
                  padding: "4px 8px", // Smaller padding after breakpoint 'md'
                  fontSize: '0.875rem', // Smaller font size after breakpoint 'md'
                },
              }}
            >
              YES, START
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleStartToggle}
            sx={{
              backgroundImage: "linear-gradient(to right, #F9FF53, #2DDE91)",
              color: "black",
              border: "none",
              borderRadius: "20px",
              mt: 2,
              "&:hover": {
                backgroundImage: "linear-gradient(to right, #F9FF53, #2DDE91)",
                opacity: 0.9,
              },
              [theme.breakpoints.down('md')]: {
                padding: "4px 8px", // Smaller padding after breakpoint 'md'
                fontSize: '0.875rem', // Smaller font size after breakpoint 'md'
              },
            }}
          >
            {account?.numPlays && account?.numPlays > 0
              ? "NO, CANCEL"
              : "CLOSE"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  const [hamburgerMenu, setHamburgerMenu] = useState(false);

  const handleHamburgerMenuClick = () => {
    setHamburgerMenu(!hamburgerMenu);
  };

  return (
    <Grid container>
      <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            zIndex: "2",
            left: "0",
            top: "1.25rem",
            ml: "1.5rem",
            position: "absolute",
          }}
        >
          <IconButton
            sx={{ color: "#2DDE91", display: { xs: "block", lg: "none" } }}
            onClick={handleHamburgerMenuClick}
          >
            <MenuIcon
            />
          </IconButton>
        </Box>

      <Grid
        bgcolor={"rgb(7,33,22)"}
        item
        xs={hamburgerMenu ? 12 : 0}
        lg={3}
        justify-content="center"
        sx={{
          borderRight: "1px solid #333",
          height: "100vh",
          display: { xs: hamburgerMenu ? "block" :"none", lg: "block" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box
            sx={{
              padding: "2rem 2rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "0.5px solid #2DDE91",
              backgroundColor: "rgb(7,33,22,0.7)",
            }}
          >
            <Typography variant="h4" sx={{ color: "#2DDE91" }}>
              A.L.I.C.E  AI System
            </Typography>
            <Button
              onClick={handleLogout}
              sx={{
                color: "#2DDE91",
                "&:hover": { color: "#FFFFFF", backgroundColor: "#2DDE91" },
              }}
            >
              <ExitToAppIcon sx={{ mr: 1 }} />
              Logout
            </Button>
          </Box>
          <Box
            sx={{
              padding: "1rem 0",
              borderLeft: "0.5px solid #2DDE91",
              borderRight: "0.5px solid #2DDE91",

              backgroundColor: "rgb(7,33,22,0.7)",
            }}
          >
            <Typography
              variant="h5"
              sx={{ color: "#2DDE91", textAlign: "center" }}
            >
              Choose a Session
            </Typography>
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              border: "0.5px solid #2DDE91",
              backgroundColor: "rgb(7,33,22,0.7)",
              flexGrow: 1,
            }}
          >
            <List>
              {tracks.map((track) => (
                <ListItem variant="h3" key={track.id}>
                  <ListItemButton
                    onClick={() => {
                      handleTrackClick(track.id);
                      handleHamburgerMenuClick();
                    }}
                  >
                    <ListItemText
                      sx={{ color: "#2DDE91", textAlign: "center" }}
                      primary={track.name}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        lg={9}
        sx={{
          backgroundColor: "black",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        

        <Paper
          sx={{
            backgroundColor: "black",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "70%",
          }}
        >
          {!selectedTrack && (
            <>
                <Typography variant="h4" style={{ color: "#A8411F" }}>
                  Nothing Selected
                </Typography>

                <Box>
                  <svg
                    xmlns="/assets/clear-search.svg"
                    viewBox="0 0 16 16"
                    width="48"
                    height="48"
                    ></svg>
                </Box>

                <Hidden smDown>
                  <Typography variant="body1" sx={{ color: "white", marginTop: 5 }}>
                    Select an session to begin. Don't see the session you're looking
                    for? Contact Us.
                  </Typography>
                </Hidden>

                <Hidden smUp>
                  <Typography variant="body1" sx={{ color: "white", marginTop: 5 }} align="center">
                    <b>Press the hamburger icon on the top left to select a session.</b> <br/><br/> Don't see the session you're looking 
                    for? Contact Us.
                  </Typography>
                </Hidden>
            </>
          )}
          {selectedTrack && (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: "#2DDE91", textAlign: "center" }}
              >
                {tracks.find((track) => track.id === selectedTrack)?.name}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  marginTop: 2,
                  textAlign: "center",
                  color: "#A8411F",
                }}
              >
                Number of remaining session(s): {account?.numPlays}
              </Typography>
              <Typography variant="body1" sx={{ color: "white", marginTop: 5 }}>
                {
                  tracks.find((track) => track.id === selectedTrack)
                    ?.description
                }
              </Typography>
              <Typography variant="h6" sx={{ color: "#CBCF43", marginTop: 5 }}>
                Please read before you start
              </Typography>
              <Box sx={{ color: "white", marginTop: 1 }}>
                {(() => {
                  const track = tracks.find(
                    (track) => track.id === selectedTrack
                  );
                  if (track && track.instructions) {
                    let instructions;
                    try {
                      instructions =
                        typeof track.instructions === "string"
                          ? JSON.parse(track.instructions)
                          : track.instructions;
                      if (
                        Array.isArray(instructions) &&
                        instructions.length > 0
                      ) {
                        return instructions.map((instruction, index) => (
                          <Typography key={index} variant="body1">
                            {index + 1}. {instruction}
                          </Typography>
                        ));
                      }
                    } catch (error) {
                      console.error("Error parsing instructions:", error);
                      return "Error displaying instructions";
                    }
                  }
                  return "No instructions available";
                })()}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<PlayArrowIcon />}
                  color="primary"
                  onClick={handleStartToggle}
                  sx={{
                    backgroundImage:
                      "linear-gradient(to right, #F9FF53, #2DDE91)",
                    color: "black",
                    border: "none",
                    borderRadius: "20px",
                    mt: 5,
                    "&:hover": {
                      backgroundImage:
                        "linear-gradient(to right, #F9FF53, #2DDE91)",
                      opacity: 0.9,
                    },
                  }}
                >
                  Start
                </Button>
              </Box>
            </Box>
          )}

          {openConfirmationModal && renderConfirmationModal()}
        </Paper>
      </Grid>
    </Grid>
  );
};
export default Tracks;
