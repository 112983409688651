import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { UserContext } from '../context/user-context/index';


const Home = ({ }) => {
  const navigate = useNavigate();
  const [inputCode, setInputCode] = useState('');
  const [loading, setLoading] = useState(false);
  const { getAccount } = useContext(UserContext);

  const handleAccessCodeChange = (event) => {
    setInputCode(event.target.value);
  };

  const handleAccessCodeSubmit = async () => {

    setLoading(true);

    const isValidCode = await getAccount(inputCode);
    if (isValidCode) {
      localStorage.setItem('accessCode', inputCode);
      navigate('/AgreePage');
    } else {
      alert('Incorrect access code.');
    }

    setLoading(false);
  };


  return (
    <Box
      sx={{
        position: 'relative', // Needed to position the video and overlay
        height: '100vh',
        color: 'white',
        p: 2,
        overflow: 'hidden', // Prevents any overflow from the video
      }}
    >
      <video 
        autoPlay
        muted
        loop
        playsInline
        disablePictureInPicture
        controlsList="nodownload nofullscreen noremoteplayback"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          objectFit: 'cover',
          zIndex: -1,
        }}>
        <source src="/assets/qnh.mp4" type="video/mp4" />
      </video>
      <Typography variant="h5" sx={{ position: 'absolute', top: 30, left: 20 }}>
        A.L.I.C.E  AI System
      </Typography>
      <Box
        sx={{
          position: 'absolute', // Position the content overlay
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1, // Above the video
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: 'sm',
            mx: 'auto',
            textAlign: 'center',
            p: 4,
            borderRadius: '8px',
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darker background for the content
          }}
        >
          <Typography variant="h4" gutterBottom>
            A.L.I.C.E  AI System
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Welcome to the A.L.I.C.E  AI System. The first AI system to leverage energy signatures. 
          </Typography>
          {loading ? <CircularProgress sx={{ margin: "5px" }} color="inherit" /> :
            <>
              <TextField
                label="Access Code"
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleAccessCodeChange}
                InputProps={{
                  style: {
                    color: 'white',
                    borderBottom: '1px solid white',
                  },
                }}
                InputLabelProps={{
                  style: { color: 'white' },
                }}
              />
              <Button
                variant="contained"
                size="large"
                onClick={handleAccessCodeSubmit}
                sx={{
                  backgroundImage: 'linear-gradient(to right, #F9FF53, #2DDE91)',
                  color: 'black',
                  border: 'none',
                  borderRadius: '20px',
                  mt: 2,
                  '&:hover': {
                    backgroundImage: 'linear-gradient(to right, #F9FF53, #2DDE91)',
                    opacity: 0.9,
                  },
                }}
              >
                Gain Access
              </Button>
            </>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
