import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth, Amplify } from "aws-amplify";
import '@aws-amplify/ui-react/styles.css';
import { useAuthenticator, Authenticator } from '@aws-amplify/ui-react';

// aws config files 
import awsconfig from "./aws-exports";
import awsconfigProduction from "./aws-prod-exports";

import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";

if (process.env.NODE_ENV !== 'production') {
  Amplify.configure(awsconfig);
  // Sentry.init({ // dev test
  //   dsn: "https://018be8213a6a47f9ba0050a5179f70d3@o480765.ingest.sentry.io/5869010",
  //   integrations: [new Integrations.BrowserTracing()],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // });
} else {
  // Sentry.init({
  //   dsn: "https://018be8213a6a47f9ba0050a5179f70d3@o480765.ingest.sentry.io/5869010",
  //   integrations: [new Integrations.BrowserTracing()],
  //   release: process.env.REACT_APP_SENTRY_RELEASE,
  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // });
  Amplify.configure(awsconfigProduction);
}

// https://mui.com/material-ui/customization/theming/
// https://bareynol.github.io/mui-theme-creator/ 
const theme = responsiveFontSizes(createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#FFF',
      dark: '#960e39',
    },
    secondary: {
      main: '#f50057',
    },
    text: {
      primary: '#6E6E6E',
      secondary: '#BE1B2B',
      light: '#777',
      disabled: '#FFFFFFA0',
    },
    background: {
      paper: '#EBEBEB',
      default: '#777',
    },
  },
  typography: {
    h1: {
      fontSize: '55px',
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    }
  },
}));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Authenticator.Provider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>

    </Authenticator.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
